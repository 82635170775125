import { CollectionOrder, HexOrder } from "@hex/common";
import { useMemo } from "react";

import { HomeQueryParamsKey, useQueryParams } from "../../route/routes.js";
import {
  SelectedSortOrderAndDirection,
  useSelectedSortOrder,
} from "../common/table/tableHooks.js";
import { ViewCountDuration } from "../hex-list/ViewCountDuration.js";

export type SelectedHexSortOrderAndDirection =
  SelectedSortOrderAndDirection<HexOrder>;

export function useSelectedSortHexOrder(
  defaultSelection?: SelectedHexSortOrderAndDirection,
): SelectedHexSortOrderAndDirection {
  // Just push through to the generic function, but add a default
  return useSelectedSortOrder(
    defaultSelection ?? {
      direction: "DESC" as const,
      sortOrder: HexOrder.RECENTLY_VIEWED,
    },
  );
}

/** Returns the selected view count duration from the page's query params. If none is specified, defaults to "all_time" */
export function useSelectedViewCountDuration(): ViewCountDuration {
  const queryParams = useQueryParams();

  const selectedViewCountDuration = useMemo(() => {
    const durationParam = queryParams.get(
      HomeQueryParamsKey.VIEW_COUNT_DURATION,
    );
    return durationParam != null
      ? (durationParam.toUpperCase() as ViewCountDuration)
      : ViewCountDuration.ALL_TIME;
  }, [queryParams]);

  return selectedViewCountDuration;
}

/** Returns the sort order from the page's query params. If none is specified, defaults to "name" */
export function useSelectedSortCollectionOrder(): CollectionOrder {
  const queryParams = useQueryParams();

  const selectedSortOrder = useMemo(() => {
    const sortParam = queryParams.get(HomeQueryParamsKey.SORT);
    return sortParam != null
      ? (sortParam.toUpperCase() as CollectionOrder)
      : CollectionOrder.NAME;
  }, [queryParams]);

  return selectedSortOrder;
}
